.descriptionRichText {
  margin: 0;
}

.descriptionRichText p {
  font-size: 16px;
  line-height: 1.2;
  font-family: Inter, normal;
  font-weight: 400;
  color: #676767;
  margin: 0;
}

.descriptionRichTextIsMobile p {
  font-size: 13px;
  line-height: 1.2;
  font-family: Inter, normal;
  font-weight: 400;
  color: #676767;
  margin-top: -5px;
}

.descriptionRichTextWhite p {
  font-size: 16px;
  line-height: 1.2;
  font-family: Inter, normal;
  font-weight: 400;
  color: white;
  margin: 0;
}

/*.descriptionRichText p:nth-child(2) {*/
/*  margin-top: 42px;*/
/*}*/

.descriptionRichText p a {
  font-size: 16px;
  line-height: 1.2;
  font-family: Inter, normal;
  font-weight: 400;
  color: #063F58;
  text-decoration-line: underline;
}
