progress {
    width: 100%;
    appearance: none;
    height: 5px;
    position: absolute;
    left: 0;
    bottom: 4px;
    background-color: #404040;
    border: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden;
}

progress::-webkit-progress-bar {
    height: 5px;
    background-color: #404040;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden;
}

progress::-webkit-progress-value {
    height: 5px;
    background-color: #F05B4D;
}

progress::-moz-progress-bar {
    height: 5px;
    background-color: #F05B4D;
}
